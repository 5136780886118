<template>
	<div class="flex column text-left">
		<div style="max-width: 100%" v-if="is_ready">
			<h1 class="setting-title">Self service user registration: {{ cg.name }}</h1>
			<q-list bordered separator class="q-mb-md rounded-borders">
				<q-item
					v-for="(instance, index) in registration_template_instances"
					:key="instance.id"
					class="hover"
				>
					<div class="flex justify-between full-width items-center">
						<span>
							{{
								instance.friendly_name
									? instance.friendly_name
									: '(No friendly name)'
							}}
						</span>
						<div>
							<q-btn
								flat
								round
								color="dark"
								icon="o_settings"
								:to="{ name: 'Edit RTI', params: { rti_id: instance.id, instance_index: index } }"
							/>
							<q-btn
								@click.stop="
									delete_dialog = true
									instance_index = index
								"
								flat
								round
								color="dark"
								icon="o_delete"
							/>
						</div>
					</div>
				</q-item>
			</q-list>
			<q-dialog v-model="delete_dialog">
				<q-card>
					<q-card-section>
						<div>
							<span>Are you sure you want to delete this template?</span>
						</div>
					</q-card-section>
					<q-card-actions align="right">
						<q-btn flat label="Cancel" color="primary" v-close-popup />
						<q-btn
							flat
							label="Confirm"
							color="primary"
							@click="deleteInstance()"
						/>
					</q-card-actions>
				</q-card>
			</q-dialog>
		</div>
		<div class="field-fab-wrapper">
			<q-btn
				v-if="$q.screen.gt.sm"
				rounded
				icon="add"
				padding="md lg"
				label="Create Template"
				color="primary"
				:to="{ name: 'Edit RTI Settings', params: { rti_id: 'add', instance_index: 0 } }"
			/>
			<q-btn
				v-else
				round
				icon="add"
				size="lg"
				color="primary"
				:to="{ name: 'Edit RTI Settings', params: { rti_id: 'add', instance_index: 0 } }"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Create Template
				</q-tooltip>
			</q-btn>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import RegistrationTemplateInstance from './registration/RegistrationTemplateInstance.vue'
export default {
	name: 'Registration',
	components: {
		RegistrationTemplateInstance,
	},
	data() {
		return {
			delete_dialog: false,
			is_ready: false,
			instance_index: -1,
		}
	},
	computed: {
		...mapGetters(['registration_template_instances', 'rti_error_message']),
		cg() {
			return this.$store.getters.customer_groups.filter(
				(cg) => cg.id == this.$route.params.id
			)[0]
		},
	},
	watch: {
		rti_error_message: function () {
			if (this.rti_error_message) {
				this.$q.notify({
					timeout: 6700,
					message: this.rti_error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			}
		},
	},
	methods: {
		async deleteInstance() {
			await this.$store.dispatch('deleteInstance', this.instance_index)
			if (!this.rti_error_message) {
				this.$q.notify('The template has been successfully deleted.')
			}
			this.delete_dialog = false
		},
	},
	async mounted() {
		this.$q.loading.show({
			delay: 400,
		})
		await this.$store.dispatch('getRegistrationTemplateInstances')
		this.is_ready = true
		this.$q.loading.hide()
	},
}
</script>
